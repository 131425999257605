html,body {
  background: #EFF3F4;
}
.hero-body .container {
  max-width: 700px;
}
.hero-body .title {
  color: hsl(192,17%,99%) !important;
}
.hero-body .subtitle {
  color: hsl(192,17%,99%) !important;
  padding-top: 2rem;
  line-height: 1.5;
}
.features {
  padding: 5rem 0;
}
.box.cta {
  border-radius: 0;
  border-left: none;
  border-right: none;
}
.card-image > .fa {
  font-size: 6rem;
  padding-top: 2rem;
  padding-bottom: 1rem;
  color: #209cee;
}
.card-content .content {
  font-size: 14px;
  margin: 1rem 1rem;
}
.card-content .content h4 {
  font-size: 16px;
  font-weight: 700;
}
.card {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.18);
  margin-bottom: 2rem;
}
.intro {
  padding: 5rem 0;
  text-align: center;
}
.sandbox {
  padding: 5rem 0;
}
.tile.notification {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.is-shady {
animation: flyintoright .4s backwards;
background: #fff;
box-shadow: rgba(0, 0, 0, .1) 0 1px 0;
border-radius: 4px;
margin: 10px;
position: relative;
transition: all .2s ease-in-out;
}
.is-shady:hover {
box-shadow: 0 10px 16px rgba(0, 0, 0, .13), 0 6px 6px rgba(0, 0, 0, .19);
}
/*adds font awesome stars*/
footer li:before {
  content: '\f1b2';
  font-family: 'FontAwesome';
  float: left;
  margin-left: -1.5em;
  color: #147efb;
}

.columns {
  margin-right: 0 !important;
}

.features {
  margin-right:  0 !important;
}
.graph {
  margin: 0 !important;
}


/* Stop emoji being italic */
.card-image > .fa {
  font-style: normal;
}

/* Set car icon size */
.car-icon {
  height: 1.5em;
}

input.numberplate {
  font-family: 'REG';
  text-transform: uppercase;
  background-color: yellow;
  height: 4rem;
  border-radius: 5px;
}

input.numberplate.input {
  font-size: 4rem;
  line-height: 0;
  padding: 0;
}


input.numberplate.input:focus, input.numberplate.input:active{
  border: 0;
  box-shadow: none;
}

::-webkit-input-placeholder {
  color: black;
}
::-moz-placeholder {
  color: black;
  font-size: 4rem;
}
:-ms-input-placeholder {
  color: black;
  font-size: 4rem;
}
::placeholder {
  color: black;
  font-size: 4rem;
}

@font-face {
  font-family: 'REG';
  src: url('./uk-reg.woff2') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.hero-body a {
  color: #e6e6e6 !important;
}

#switch-selector {
  margin-top: 10px;
}

#switch-selector a:hover {
  text-decoration: underline;
}